<template>
  <div v-if="isLoaded" class="transition-wrapper d-flex flex-column">
    <slot />
  </div>
</template>

<script>
import {
  ref,
} from 'vue';

export default {
  setup() {
    const isLoaded = ref(false);

    const onPageLoad = async () => {
      await new Promise((resolve) => { setTimeout(resolve, 0); });
      isLoaded.value = true;
    };

    onPageLoad();

    return {
      isLoaded,
    };
  },
};
</script>

<style lang="scss" scoped>
.transition-wrapper {
  width: 100%;
  height: 100%;
}
</style>

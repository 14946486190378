<template>
  <div
    ref="dropdown"
    class="dropdown-block"
    :class="[type, {
      'is-wrapper-open': isOpen,
    }]"
    :style="attrs"
    tabindex="0"
    @keypress.space="isDisabled ? () => {} : onToggle()"
  >
    <div
      class="dropdown-block-header d-flex align-items-center"
      @click="isDisabled ? () => {} : onToggle()"
    >
      <AppIcon
        v-if="iconName"
        :name="iconName"
        style="margin-right: 28px;"
        size="52px"
        class="header-icon"
      />
      <slot name="header">
        Head
      </slot>
      <FSpacer />
      <FButton
        v-if="type === 'primary' || type === 'bordered'"
        type="plain"
        is-icon
        icon="arrow-bracket"
        class="arrow-button"
        :style="buttonStyle"
        is-stop-propagation
        @click="isDisabled ? () => {} : onToggle()"
      />
      <AppIcon
        v-if="type === 'plain'"
        name="arrow-bracket"
        :class="isOpen ? 'arrow-active' : 'arrow-inactive'"
        size="18px"
      />
    </div>
    <div
      v-if="!noContent"
      ref="dropdownContent"
      class="dropdown-block-body"
      :class="{
        'is-open': isOpen,
      }"
    >
      <slot>
        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Illum at culpa consequuntur asperiores atque praesentium, maxime cupiditate hic, sint tempora expedita natus ex sapiente excepturi ipsa vel autem ullam consectetur?
      </slot>
    </div>
  </div>
</template>

<script>
import {
  computed, defineComponent, reactive, ref,
} from 'vue';

export default defineComponent({
  props: {
    type: {
      type: String,
      default: 'primary',
      validator: (value) => ['primary', 'plain', 'blank', 'bordered'].includes(value),
    },
    noContent: {
      type: Boolean,
      default: false,
    },
    iconName: {
      type: String,
      default: '',
    },
    height: {
      type: String,
      default: '1000px',
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['toggle'],
  setup(props, { emit }) {
    const isOpen = ref(false);
    const buttonStyle = computed(() => `
      border-radius: 50%;
      transform: rotate(${isOpen.value ? '0' : '180deg'})
    `);

    const dropdown = ref(null);
    const dropdownContent = ref(null);

    const onToggle = () => {
      isOpen.value = !isOpen.value;
      setTimeout(() => {
        window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
      }, 100);
      emit('toggle', isOpen.value);
    };

    const attrs = reactive({
      '--height': props.height,
    });

    return {
      dropdown,
      dropdownContent,

      isOpen,
      buttonStyle,
      onToggle,

      attrs,
    };
  },
});
</script>

<style scoped lang="scss">
.arrow-button {
  background: var(--color-black-005);
  @include transition-base;
}

.dropdown-block {
  @include focus-visible;
  .dropdown-block-header {
    cursor: pointer;
    &:hover {
      .arrow-button {
        background: #FBF3EC;
        &:deep(.icon path) {
          fill: var(--color-primary) !important;
        }
      }
    }
  }
  .dropdown-block-body {
    height: 0;
    overflow: hidden;
    padding: 0;

    @include transition-base(height);
    &.is-open {
      height: var(--height);
    }
  }
}
.dropdown-block.primary {
  .dropdown-block-header {
    padding: 40px 45px;

    .header-icon {
      &:deep(path:first-child) {
        fill: #8A90A4 !important;
        @include transition-base;
      }
    }

    &:hover {
      .header-icon {
        &:deep(path:first-child) {
          fill: var(--color-primary) !important;
        }
      }
    }
  }

  .dropdown-block-body {
    padding: 0 45px;
  }
}

.dropdown-block.plain {
  background: #F7F7F7;
  border-radius: 6px;

  .dropdown-block-header {
    height: 53px;
    padding: 0 30px;

    &:hover {
      cursor: pointer;
      background: #EDEDED;
      border-radius: 6px;
    }
  }

  .arrow-inactive {
    transition: 0.2s transform;
    transform: rotate(180deg);
    @include transition-base(transform);
  }

  .arrow-active {
    transition: 0.2s transform;
    transform: rotate(0deg);
    @include transition-base(transform);
  }
}

.dropdown-block.blank {
  background-color: transparent;
}

.dropdown-block.bordered {
  border: 1px solid var(--color-black-007);
  border-radius: 4px;
  .dropdown-block-header {
    padding: 20px 28px;
    align-items: flex-start;
    .arrow-button {
      margin-top: 15px;
      width: 28px;
      height: 28px;
      flex: 1 0 28px;
    }
  }
  .dropdown-block-body.is-open {
    padding: 0 28px 20px;
  }
}
</style>
